export function getNewsUrl(slug, typeOfArticle) {
  switch (typeOfArticle) {
    case 'Perspectives':
      return `/insights/perspectives/${slug}`
    case 'Case Study':
      return `/insights/case-studies/${slug}`
    case 'News':
      return `/news/${slug}`
    default:
      return `/insights/blogs/${slug}`
  }
}
