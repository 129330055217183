import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css, theme } from 'twin.macro'
import ArticleGrid from '../../components/article-grid'
import Banner from '../../components/banner'
import FeaturedArticle from '../../components/featured-article'
import Hero from '../../components/hero'
import Layout from '../../components/layout'
import SubNav from '../../components/sub-nav'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'
import { getNewsUrl } from '../../utils/routing'

const InsightsPage = ({ data: { insight }, location }) => {
  const page = useMemo(() => flatten(insight || {}, ['hero', 'featured', 'banner']), [insight])
  const anchorLinks = useMemo(
    () => [
      {
        label: get(page, 'featuredLabel'),
        link: '#featured',
        active: location.hash === '' || location.hash === '#featured',
      },
      {
        label: get(page, 'blogArticlesLabel'),
        link: '#blog-articles',
        superscript: get(page, 'blogArticles' || []).length,
      },
    ],
    [page]
  )
  const blogArticles = useMemo(
    () =>
      (get(page, 'blogArticles') || []).map((article) => ({
        type: article.typeOfArticle,
        date: article.date,
        headline: article.headline,
        excerpt: get(article, 'contentNode.childMarkdownRemark.excerpt'),
        callToAction: {
          label: get(page, 'blogArticlesButtonLabel'),
          link: getNewsUrl(article.slug, article.typeOfArticle),
        },
        image: article.image,
      })).sort((a, b) => new Date(b.date) - new Date(a.date)),
    [page]
  )

  return (
    <Layout
      pageTags={page.seoMetaTags}
      noIndex={page.noIndex}
      headerTransition={true}
      headerTransitionColor="white"
    >
      <section id="hero" css={tw`relative bg-secondary-500`}>
        <Hero
          title={get(page, 'hero.titleNode')}
          description={get(page, 'hero.descriptionNode')}
          descriptionHeadingType="h3"
          style={css`
            h3 {
              ${tw`font-light`}
            }
          }`}
          extended
        />
      </section>
      <SubNav
        links={anchorLinks}
        style={css`
          ${tw`sticky z-10 bg-white bg-opacity-100 top-mobile-header lg:top-desktop-header`}
          li {
            ${tw`mx-8 first-of-type:ml-0`}
          }
          border-bottom: 1px solid #e5e5e5;
        `}
        selectBackground={theme`colors.white`}
      />
      <section id="featured" css={tw`bg-secondary-500 bg-opacity-5`}>
        <div css={global`layout.container`}>
          <FeaturedArticle
            type={get(page, 'featured.typeOfArticle')}
            date={get(page, 'featured.date')}
            headline={get(page, 'featured.headline')}
            excerpt={get(page, 'featured.contentNode.childMarkdownRemark.excerpt')}
            callToAction={{
              label: get(page, 'featuredButtonLabel'),
              link: getNewsUrl(get(page, 'featured.slug'), get(page, 'featured.typeOfArticle')),
            }}
            image={get(page, 'featured.image')}
          />
        </div>
      </section>
      <section id="blog-articles" css={tw`py-20 lg:py-32`}>
        <ArticleGrid articles={blogArticles} title={get(page, 'blogArticlesLabel')} showImages />
      </section>
      <section id="call-to-action" css={[global`layout.container`, tw`pb-20 lg:pb-32`]}>
        <Banner
          title={get(page, 'banner.titleNode')}
          subtitle={get(page, 'banner.subtitleNode')}
          description={get(page, 'banner.descriptionNode')}
          callToAction={get(page, 'banner.callToAction')}
        />
      </section>
    </Layout>
  )
}

InsightsPage.propTypes = {
  data: PropTypes.shape({
    insight: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default InsightsPage

export const query = graphql`
  query InsightsQuery {
    insight: datoCmsInsight {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      featuredLabel
      featuredButtonLabel
      featured {
        typeOfArticle
        slug
        date
        headline
        subheading
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      blogArticlesLabel
      blogArticlesButtonLabel
      blogArticles {
        typeOfArticle
        slug
        date
        headline
        subheading
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      blogArticlesSeeAll
      perspectivesLabel
      perspectivesButtonLabel
      perspectives {
        typeOfArticle
        slug
        date
        headline
        subheading
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      perspectivesSeeAll
      caseStudiesLabel
      caseStudiesButtonLabel
      caseStudies {
        typeOfArticle
        slug
        date
        headline
        subheading
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      caseStudiesSeeAll
      banner {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      backLabel
    }
  }
`
